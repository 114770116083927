<template>
  <v-dialog
    v-model="isOpen"
    class="ConfirmationDialog"
    :max-width="dialogState ? dialogState.maxWidth: 466"
  >
    <v-sheet
      v-if="dialogState"
      class="ConfirmationDialog__card"
      color="white"
    >
      <h1
        class="ConfirmationDialog__title display-3"
        v-text="dialogState.title"
      />

      <div
        class="ConfirmationDialog__subtitle"
        v-text="dialogState.subtitle"
      />

      <AppTextField
        v-if="dialogState.dataToCopy"
        ref="dataToCopyTextField"
        :value="dialogState.dataToCopy"
        readonly
        filled
        hide-details
        :label="dialogState.title"
        class="ConfirmationDialog__data-to-copy"
        @click.native="copyToClipboard"
      />

      <div class="ConfirmationDialog__actions">
        <v-spacer />
        <v-btn
          v-bind="dialogState.consentProps"
          @click="consent"
        >
          {{ dialogState.consentLabel }}
        </v-btn>
        <v-btn
          v-if="dialogState.rejectLabel"
          class="ml-2"
          v-bind="dialogState.rejectProps"
          @click="dialogState.customOnRejectBtnClick
            ? dialogState.customOnRejectBtnClick($event, reject)
            : reject()"
        >
          {{ dialogState.rejectLabel }}
        </v-btn>
      </div>
    </v-sheet>
  </v-dialog>
</template>

<script>
import copyToClipboard from 'copy-to-clipboard'

export default {
  name: 'ConfirmationDialog',

  props: {
    // see ../store/modules/confirm.js
    // example:
    // {
    //   id: 42,
    //   title: 'Delete user?',
    //   subtitle: 'Are you sure yo want to delete him or her?',
    //   consentLabel: 'Yeas, delete',
    //   consentProps: { color: 'error', depressed: true },
    //   rejectLabel: 'No, leave \'em be',
    //   rejectProps: { color: 'primary', outlined: true },
    //   maxWidth: 600,
    //   dataToCopy: 'secret-secret-token-copy-me',
    //   isOpen: true,
    // }
    dialogState: { type: Object, required: true },
  },

  computed: {
    isOpen: {
      get() { return this.dialogState.isOpen },
      set(isOpen) {
        if (!isOpen) this.reject()
      },
    },
  },

  methods: {
    consent() {
      const { $store, dialogState } = this
      $store.dispatch('confirm/consent', { dialogState })
    },
    reject() {
      const { $store, dialogState } = this
      $store.dispatch('confirm/reject', { dialogState })
    },

    copyToClipboard() {
      this.$refs.dataToCopyTextField.select()
      copyToClipboard(this.dialogState.dataToCopy)
      this.$store.commit('$snackbar/setMessage', {
        message: this.$t('layout.TokenCopiedM'),
      })
    },
  },
}
</script>

<style lang="sass" scoped>
.ConfirmationDialog
  &__card
    padding: 56px 48px 32px

  &__title
    margin-bottom: 16px

  &__subtitle
    margin: 16px 0 39px

  &__data-to-copy
    &, ::v-deep *
      cursor: pointer !important

  &__actions
    display: flex
    margin: 39px -16px 0
</style>
